<template>
  <div class="mt-4">
    <table class="table table-bordered table-hover">
      <thead class="table-primary">
        <tr>
          <th class="text-center cw-50">
            {{
              $t(
                "BaseModelFields.SerialNo",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </th>
          <th class="text-capitalize">
            {{
              $t(
                "Configurations.Key",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </th>
          <th class="text-capitalize">
            {{
              $t(
                "Configurations.Value",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </th>
          <th class="text-center">
            <button
              type="button"
              class="btn btn-sm btn-success"
              @click="addNewLine"
              :disabled="this.buttonDisabled"
            >
              <span>
                <i class="bi bi-plus" style="margin-left: 0 !important"></i>
              </span>
            </button>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in tableItems" :key="index">
          <td class="align-middle text-center" ref="index">
            {{ index + 1 }}
          </td>
          <td class="align-middle">
            <input
              type="text"
              class="form-control"
              id="Key"
              v-model="item.key"
              required
            />
          </td>
          <td class="align-middle">
            <input
              type="text"
              class="form-control"
              id="Value"
              v-model="item.value"
              required
            />
          </td>
          <td class="text-center">
            <button
              type="button"
              :class="
                String.format('btn btn-sm btn-danger removeButton_{0}', index)
              "
              @click="removeLine(index)"
              :disabled="this.buttonDisabled"
            >
              <span>
                <i class="bi bi-dash" style="margin-left: 0 !important"></i>
              </span>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import $ from "jquery";
import { createToast } from "mosha-vue-toastify";
export default {
  name: "httpConnectionTable",
  emits: ["setTableItems"],
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
    customObjectPublicId: {
      type: String,
      default: "",
    },
    isEdit: { type: Boolean, default: false },
  },
  data() {
    return {
      model: {},
      isLoading: false,
      translationItems: [],
      submitButtonHtml: String.format(
        '<span class="loading-block"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span> {0}</span></span>',
        this.$t(
          "Buttons.PleaseWait",
          {},
          { locale: this.$store.state.activeLang }
        )
      ),
      httpConnectionValueData: {
        key: "",
        value: "",
      },
      errors: [],
      tableItems: [],
    };
  },
  methods: {
    addNewLine() {
      const hasEmptyRow = this.tableItems.some(
        (item) => item.key === "" || item.value === ""
      );

      if (hasEmptyRow) {
        createToast(
          this.$t(
            "HttpConnections.WarningMessage",
            {},
            { locale: this.$store.state.activeLang }
          ),
          {
            showIcon: "true",
            position: "top-right",
            type: "warning",
            transition: "zoom",
            hideProgressBar: true,
          }
        );
      } else {
        const newLine = { key: "", value: "" };
        this.tableItems.push(newLine);
        this.$emit("setTableItems", this.tableItems);
        this.buttonDisabled = false;
      }
    },
    removeLine(index) {
      this.tableItems.splice(index, 1);

      this.$emit("setTableItems", this.tableItems);
    },

    success: function ($firstSpan, $loadingBlock) {
      $firstSpan.show();
      $loadingBlock.remove();
      this.buttonDisabled = false;
    },
    error: function ($firstSpan, $loadingBlock) {
      $firstSpan.show();
      $loadingBlock.remove();
      this.buttonDisabled = false;
    },
  },
  mounted() {
    if (this.data != null) {
      this.tableItems = this.data;
    }
    if (this.tableItems.length === 0) {
      this.addNewLine();
    }
  },
};
</script>
